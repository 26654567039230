<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CCol col="6" style="font-size: 25px;">عرض المنطقة</CCol>
        </CCardHeader>
        <CCardBody>
          <div>
            <div class="row">
              <div class="col-md-6">
                <table class="table table-bordered">
                  <thead>
                    <tr class="titel-style">
                      <th>الاسم</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-model="area.name" :key="area.id" id="area">
                      <td>{{ area.name }}</td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr class="titel-style">
                      <th>المدينة</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-model="selected" :key="selected.id" id="cities">
                      <td>{{ selected.name}}</td>
                    </tr>
                  </tbody>
                </table>
                <!-- <form @submit.prevent="UpdateArea">
                  <div class="form-group">
                    <label for="cities">
                      المدن
                    </label>
                    <select v-model="selected" id="cities" class="form-control" required>
                      <option v-for="option in cities" v-bind:value="option.id">
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </form> -->
              </div>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-6">
              <table class="table table-bordered">
                <thead>
                <tr class="titel-style">
                  <th>المؤسسات</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="entity in entities" :key="entity.id" id="entites">
                  <td>{{ entity.name }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <CButton color="warning" @click="goBack">رجوع</CButton>
        </CCardBody>
        
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import swal from 'sweetalert'
    import $ from 'jquery'
    export default {
        name : 'ShowArea',
        data() {
            return {
                area: [],
                image:'',
                cities:[],
                city:[],
                selected: '',
                entities:[],
            }
        },
        created() {
            
            console.log(this.$route.params.id);
            this.$http
                .get(`${process.env.VUE_APP_URL}areas/${this.$route.params.id}/edit`)
                .then((response) => {
                    this.area = response.data.data.area;
                    this.selected = response.data.data.area.city_id;
                    this.selected = this.area.city;
                });
            let cities = [];
            this.$http
            .get(`${process.env.VUE_APP_URL}cities-list`)
            .then((response) => {
                console.log(response);
                $.each(response.data.data, function(key, value) {
                    cities.push({id:value.id,name:value.name})
                });
                this.cities = cities;
                console.log(this.area ,"hi")
            });
            let entities = [];
            this .$http
                 .get(`${process.env.VUE_APP_URL}areas/${this.$route.params.id}/entities`)
                 .then((response) => {
                    $.each(response.data.data, function(key, value) {
                        entities.push({id:value.id,name:value.name})
                    });
                    this.entities = entities;
                });
        },
        methods: {
            goBack() {
                this.areasOpened ? this.$router.go(-1) : this.$router.push({path: '/areas'})
            },
            
        }
    }

</script>
